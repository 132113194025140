
const apiCall = async (url, request) => {
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(request)
      });
    
    const data = await response.json();
    if (!response.ok || data.StatusCode !== 200) throw new Error(response.Message);
    return JSON.parse(data.Payload)
};

const adminCall = async(request) => await apiCall("https://z7ktmpmeqpgivgvsd6fyo34bla0wyyhi.lambda-url.ap-southeast-2.on.aws/", request);
const createCall = async(ModelType, Payload) => await adminCall({ModelType, Payload, Method:"Create"});

const readCall = async(ModelType, key) => await adminCall({ModelType, key, Method: "Read"});
const listByParentCall = async(ModelType, ParentId) => await adminCall({ModelType, ParentId, Method: "ReadListByParent"});
const listAllCall = async(ModelType) => await adminCall({ModelType, Method: "ReadAll"});
const extractNamespace = (modelType) => modelType.substr(modelType.lastIndexOf('.') + 1);

export default {
    apiCall,
    adminCall,
    createCall,
    listByParentCall,
    listAllCall,
    readCall
}
import React, { useEffect } from 'react';
import api from './api';
import { RetellWebClient } from "retell-client-js-sdk";

const sdk = new RetellWebClient();
var initCalled = false;
var isCalling = false;
var isBusy = false;
var rego = {};

function App() {
  console.log("version: 0.4.2");

  useEffect(() => {
    const init = async () => {
      if(initCalled) return;
      initCalled = true;
      try {
        const result = await api.adminCall({
          Method: 'Launch',
          Chain: 'Easy/Home Page Rego',
          Payload: window.location.href
        });

        console.log("rego:", result);
        rego = result;
        turnGreen();

      } catch (error) {
        console.error("Failed to initialize:", error);
      }
    };
    
    init();

    return () => {
      window.removeEventListener('phoneClick', handlePhoneClick);
    };
  }, []);

  const turnGreen = () => {
    var element = document.getElementById("phone");
    element.style.color = "#7ED321";
    var element = document.getElementById("call-icon");
    element.style.color = "#7ED321";
  };

  const turnRed = () => {
    var element = document.getElementById("phone");
    element.style.color = "#f84343";
    var element = document.getElementById("call-icon");
    element.style.color = "#f84343";
  };

  const handlePhoneClick = (event) => {
    if (isBusy) 
    {
      console.log("stopping..");
      sdk.stopConversation();
    } 
    else 
    {
      if(isCalling) return;
      console.log("calling..");

      sdk.startConversation({
        callId: rego.call_id,
        sampleRate: rego.sample_rate,
      });
    }

    isBusy = !isBusy;
    if(isBusy) turnRed(); else turnGreen();
  };

  window.addEventListener('phoneClick', handlePhoneClick);

  return (
    <div>
    </div>
  );
} 

export default App;